import { React } from '@/app'
import { View, Page } from '@/components'
import { Legal } from '../layout/Legal'

const LegalPage: React.FC = () => {
  return (
    <Page title='App Terms & Conditions' center={false}>
      <View variants={['center', 'fullWidth', 'marginTop:6', 'paddingTop:8', 'paddingBottom:8']} style={{ backgroundColor: '#fff' }}>
        <Legal />
      </View>
    </Page>
  )
}

export default LegalPage
